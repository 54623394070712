import { useState, useEffect } from 'react'
import _pick from 'lodash/pick'
import { useField, useForm } from 'react-final-form-hooks'

import { Button } from '../../../../../components/ui/Button'
import { copyKey } from '../../../../../services/keys'
import { KeyCopyFormProps } from '../types/KeyCopyForm.types'
import { Container, Heading } from '../styles/KeyForm.sc'
import { FINAL_STATUSES } from '../types/FinalBlock.types'
import { composeValidators } from '../utils/validation'
import { decodeValues, encodeValues, NAMES, PLACEHOLDERS, VALIDATORS } from './forms.helpers'
import KeyInput from './KeyInput'
import Field from './Field'
import { keyHasBeenClonedEvent, authOkEvent, addNewkeyStartEvent } from '../../../../../events/keys'

const KeyCopyForm = (props: KeyCopyFormProps) => {
  const { state, getReCaptcha, onFinish } = props

  useEffect(authOkEvent, [])

  const [loading, setLoading] = useState(false)

  const { form, handleSubmit } = useForm({
    initialValues: encodeValues(state),

    async onSubmit(data) {
      setLoading(true)

      // All values
      const decoded = decodeValues(data)

      const recaptcha = await getReCaptcha()

      addNewkeyStartEvent()

      try {
        // Only needed for request
        const payload = _pick(decoded, ['keyCode', 'cloneKeyCode', 'houseId', 'flat', 'operatorId'])

        await copyKey(payload, { recaptcha })

        onFinish(decoded, {
          status: FINAL_STATUSES.SUCCESS,
        })

        keyHasBeenClonedEvent(decoded)
      } catch (error: any) {
        let message

        if (error.isAxiosError) {
          const { content } = error?.response?.data || {}

          if (content && content.errorMessage) {
            message = content.errorMessage
          }

          onFinish(decoded, {
            status: FINAL_STATUSES.FAILED,
            message,
          })
        }
      }

      setLoading(false)
    },
  })

  const cloneKeyCode = useField(
    NAMES.cloneKeyCode,
    form,
    composeValidators([
      VALIDATORS[NAMES.cloneKeyCode],
      (value: string) => {
        if (state.keyCode === value) {
          return 'Ключи не должны совпадать'
        }
      },
    ]),
  )

  return (
    <Container as="form" onSubmit={handleSubmit}>
      <Heading level={5}>Введите номер нового ключа</Heading>
      <Field final={cloneKeyCode} as={KeyInput} placeholder={PLACEHOLDERS[NAMES.cloneKeyCode]} />

      <Button block size={48} variant="action" loading={loading}>
        Продолжить
      </Button>
    </Container>
  )
}

export default KeyCopyForm
