import { useCallback, useState, useRef, useEffect } from 'react'
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3'

import { Heading } from '../../../components/ui/Typography'
import { FinalBlockOptions } from '../components/form/types/FinalBlock.types'
import { STEPS } from '../constants/Home.constants'
import { Banner, FinalBlock, KeyCopyForm, KeyForm, Support } from '../components/form'
import { Container, Content, ContentBlock, SubHeading } from '../styles/Home.sc'
import { IFormState } from '../components/form/types/form.types'
import { runtimeConfig } from '../../../config'
import { openPageEvent } from '../../../events/keys'

const RECAPTCHA_ACTION = 'key_form'
const { RECAPTCHA_CLIENT_SECRET } = runtimeConfig

const makeControllablePromise = () => {
  let resolver

  const promise = new Promise((resolve) => (resolver = resolve))

  return {
    promise,
    resolver,
  }
}

const HomePage = () => {
  const reCaptchaRef = useRef(null)
  const [state, setState] = useState({})
  const [step, setStep] = useState<string>(STEPS.KEY_FORM)
  const [finalState, setFinalState] = useState<FinalBlockOptions>()

  const reCaptchaPromiseRef = useRef<ReturnType<typeof makeControllablePromise>>(makeControllablePromise())

  useEffect(() => {
    loadReCaptcha(RECAPTCHA_CLIENT_SECRET)
    openPageEvent()
  }, [])

  const handleChangeStep = useCallback((step: STEPS, patch?: Partial<IFormState>) => {
    if (patch) {
      setState((state) => ({
        ...state,
        ...patch,
      }))
    }

    setStep(step)
  }, [])

  const handleChangeToken = useCallback((token) => {
    // @ts-ignore
    reCaptchaPromiseRef.current.resolver({
      token,
      action: RECAPTCHA_ACTION,
    })
  }, [])

  const getReCaptcha = useCallback(() => {
    reCaptchaPromiseRef.current = makeControllablePromise()

    if (reCaptchaRef.current) {
      // @ts-ignore
      reCaptchaRef.current.execute()
    }

    return reCaptchaPromiseRef.current.promise as Promise<{
      token: string
      action: string
    }>
  }, [reCaptchaRef.current])

  const handleKeyFormFinish = useCallback((patch) => {
    handleChangeStep(STEPS.KEY_COPY_FORM, patch)
  }, [])

  const handleKeyCopyFormFinish = useCallback((patch, finalState: FinalBlockOptions) => {
    setFinalState(finalState)
    handleChangeStep(STEPS.FINAL, patch)
  }, [])

  let content

  switch (step) {
    case STEPS.KEY_FORM: {
      content = (
        <>
          <ContentBlock>
            <Heading level={3}>
              Активация нового
              <br />
              ключа для домофона
            </Heading>
            <SubHeading size={20}>
              Активируйте ключ домофона от дома,
              <br />в котором вы живете
            </SubHeading>
          </ContentBlock>
          <ContentBlock indent={32}>
            <Banner />
          </ContentBlock>
          <ContentBlock indent={20}>
            <KeyForm getReCaptcha={getReCaptcha} state={state} onFinish={handleKeyFormFinish} />
          </ContentBlock>
          <ContentBlock indent={24}>
            <Support />
          </ContentBlock>
        </>
      )

      break
    }

    case STEPS.KEY_COPY_FORM: {
      content = (
        <>
          <ContentBlock>
            <Heading level={3}>Создание копии ключа</Heading>
          </ContentBlock>
          <ContentBlock indent={20}>
            <KeyCopyForm getReCaptcha={getReCaptcha} state={state} onFinish={handleKeyCopyFormFinish} />
          </ContentBlock>
          <ContentBlock indent={24}>
            <Support />
          </ContentBlock>
        </>
      )

      break
    }

    case STEPS.FINAL: {
      content = <FinalBlock {...finalState} onChangeStep={handleChangeStep} />

      break
    }
  }

  return (
    <Container>
      <Content>{content}</Content>
      <ReCaptcha ref={reCaptchaRef} sitekey={RECAPTCHA_CLIENT_SECRET} action={RECAPTCHA_ACTION} verifyCallback={handleChangeToken} />
    </Container>
  )
}

export default HomePage
